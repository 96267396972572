import Axios from 'axios';

export default {
  data() {
    return {
      page: 1,
      last_page: 1,
      total: 0,
      hirings: [],
      specialities: [],
      companies: [],
      users: [],
      filter: {
        month: '',
        year: '',
        status: 1,
        hiring_id: '',
        name: '',
        razao: '',
        nome_fantasia: '',
      },
      orderby: 'id',
      order: 'desc',
      TOKEN: this.$store.state.token,
      banks: [],
      sync: false,
    }
  },

  mounted() {

    $('[data-toggle="tooltip"]').tooltip();

    if (this.$store.state.role == 'client') this.$router.push({ path: '/dashboard/client' })
    if (this.$store.state.role == 'professional') this.$router.push({ path: '/dashboard/professional' })

    this.sync = true;

    axios
      .get('/api/v1/specialities', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.specialities = response.data.data;
        } else {
          console.log(response.data.error);
        }
      });

    axios
      .get('/api/v1/hirings?status=' + this.filter.status, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.sync = false;
        for (var i = 0; i < response.data.data.length; i++) {
          response.data.data[i].preselected = [];
          response.data.data[i].sended = [];
          response.data.data[i].open = false;

        }
        this.hirings = response.data.data;
        this.last_page = response.data.last_page;
        this.total = response.data.total;
      });


    axios
      .get('/api/v1/users', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })

      .then(response => {
        if (!response.data.error) {
          this.users = response.data.data;
          this.name = response.data.name;

        }
      });

    axios
      .get('/api/v1/companies', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.companies = response.data.data;
          this.nome_fantasia = response.data.nome_fantasia;
        }
      });

    axios
      .get("/api/v1/banks", {
        headers: {
          Authorization: "Bearer " + this.TOKEN,
        },
      })
      .then((response) => {
        if (!response.data.error) {
          this.banks = response.data.data;
        }
      });

  },

  methods: {

    toggleOpen(hiring) {
      hiring.open = !hiring.open;
      if (hiring.open) hiring.preselected = [];
      if (hiring.open) this.cardOpen(hiring);
    },

    sortBy(sort) {
      if (this.orderby == sort) {
        this.order = (this.order == 'asc') ? 'desc' : 'asc';
        console.log(this.order);
      } else {
        this.orderby = sort;
        this.order = 'desc';
      }
    },

    cardOpen(hiring) {
      // let open = hiring.open;
      // this.hirings.forEach(function(h){
      //   h.open = false
      // });
      // hiring.open = !open;

      //filtrar só quem mandou proposta
      let vetor = [];
      if (hiring.status == 2 || hiring.status == 3) {
        vetor = hiring.providers.filter((e) => {
          if (e.pivot) return e.pivot.status == 2;
          return false;
        });
      }
      setTimeout(() => {
        hiring.sended = vetor;
      }, 100);

      if (hiring.status < 3) {
        axios
          .get('/api/v1/users/select-for-hiring/' + hiring.id, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            if (!response.data.error && hiring.open) {
              if (!response.data.data.length) {
                hiring.noOne = true;
              }
              hiring.preselected = response.data.data;
            } else {
              console.log(response.data.error);
            }
          });
      }
    },

    async addCreator(data) {

      let response = await axios
      .get('/api/v1/users/' + data.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });
      
      for (var i = 0; i < data.hiring.preselected.length; i++) {
        if (data.hiring.preselected[i].id == data.id) {
          alert('Talento já está na lista');
          return;
        }
      }

      if(response.data.data.profile_status == 0){
        alert('Creator indisponível');
        return;
      }

      if (!response.data.error) {
        data.hiring.preselected.push(response.data.data);
      } else {
        console.log(response.data.error);
      }
        
    },

    inviteCreator(param) {

      for (var i = 0; i < param.hiring.providers.length; i++) {
        if (param.hiring.providers[i].id == param.id) {
          alert('Talento já está na lista');
          return;
        }
      }

      axios
        .post('/api/v1/hirings/' + param.hiring.id + '/invite/' + param.id, {}, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            param.hiring.providers.push(response.data.data);
          } else {
            console.log(response.data.error);
          }
        });
    },

    approveBriefing(param) {
      var ids = param.selected.map(function (elem) { return elem.id });
      var data = {
        selected: ids,
        reveal: param.reveal,
      };
      axios
        .put('/api/v1/hirings/' + param.hiring.id + '/approve-briefing', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          // response from approve
          console.log(response.data);
          if (!response.data.error) {
            response.data.data.sended = [];
            param.hiring = response.data.data;
            this.$router.go();
          }
        });
    },

    progressBriefing(hiring) {
      var data = {};
      axios
        .put('/api/v1/hirings/' + hiring.id + '/hiring-status', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            response.data.data.sended = [];
            hiring = response.data.data;
            this.$router.go();
          }
        });
    },

    cancelHiring(data) {
      let hiring = data.hiring;
      let motive = data.motive;

      console.log(data);

      axios
        .put('/api/v1/hirings/' + hiring.id + '/cancel', {motive: motive}, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            response.data.data.sended = [];
            hiring = response.data.data;
            this.$router.go();
          }
        });
    },

    editHiring(data) {
      axios
        .put('/api/v1/hirings/' + data.id, data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
          } else {
            console.log(response.data.error);
          }
        });
    },

    sendInvoice(form) {
      let formData = new FormData();
      formData.append('invoice', form.file);
      formData.set('name', 'NF - Creators.llc');
      // formData.set('due_date', form.date);
      // formData.set('value', parseInt(form.value.replace(/\D/g, '')));
      formData.set('hiring', form.hiring_id);
      axios
        .post('/api/v1/invoices/', formData, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          if (!response.data.error) {
            // hiring = response.data.data;
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    newBoleto(data) {

      let formData = new FormData();
      formData.set('due_date', data.date);

      axios
        .post('/api/v1/hirings/' + data.id + '/boleto', formData, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    sendFeedback(form) {
      let formData = new FormData();
      formData.set('feedback', form.body);
      formData.set('hiring', form.hiring_id);
      axios
        .post('/api/v1/feedbacks', formData, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    changeVisibility(data) {
      axios
        .post('/api/v1/hirings/' + data.hiring + '/visibility', {
          user: data.user,
          visibility: data.visibility,
        }, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
        });
    },

    nextPage() {
      this.page++;
    },

    prevPage() {
      this.page--;
    },

  },

  computed: {

    csv() {
      let url = '/dashboard/admin/hiring/export?';
      if (this.filter.status) {
        url += 'status=' + this.filter.status;
      }
      if(this.filter.year){
        console.log('filtro de ano')
        url += '&year=' + this.filter.year;
      }

      return url;
    },

    urlQuery() {
      let url = "";
      url += '?status=' + this.filter.status;
      url += '&orderby=' + this.orderby;
      url += '&order=' + this.order;
      if (this.filter.month) {
        url += '&month=' + this.filter.month;
      }
      if (this.filter.year) {
        url += '&year=' + this.filter.year;
      }
      if (this.filter.id) {
        url += '&id=' + this.filter.id;
      }
      if (this.filter.razao) {
        url += '&razao=' + this.filter.razao;
      }
      if (this.filter.name) {
        url += '&name=' + this.filter.name;
      }
      return url;
    },
  },

  watch: {
    urlQuery() {
      this.sync = true;
      axios
        .get('/api/v1/hirings' + this.urlQuery, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          this.sync = false;
          for (var i = 0; i < response.data.data.length; i++) {
            response.data.data[i].preselected = [];
            response.data.data[i].sended = [];
            response.data.data[i].open = false;
          }
          this.page = 1;
          this.hirings = response.data.data;
          this.last_page = response.data.last_page;
          this.total = response.data.total;
        });
    },

    page: function (page) {
      this.sync = true;
      axios
        .get('/api/v1/hirings' + this.urlQuery + '&page=' + page, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          this.sync = false;
          for (var i = 0; i < response.data.data.length; i++) {
            response.data.data[i].preselected = [];
            response.data.data[i].sended = [];
          }
          this.hirings = response.data.data;
        });
    }
  }

}
